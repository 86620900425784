import { FeatureToggleConfig } from './src/types';

export const config = {
  // Here as a default / example
  'my-feature-flag': {
    defaultValue: false,
    variants: ['control', 'variation-1', 'variation-2'],
    experiment: {
      key: 'test-experiment',
      domains: ['www.sortlist-test.com', 'localhost'],
      pathnames: ['**'],
    },
  },
  'positive-review': {
    defaultValue: false,
    variants: ['control', 'variation-review'],
    experiment: {
      key: 'positive-review',
      domains: ['www.sortlist.fr', 'www-fr.sortlist-test.com', 'localhost'],
      pathnames: [
        '/site-internet/france-fr',
        '/web-marketing/france-fr',
        '/identite-visuelle/france-fr',
        '/graphisme/france-fr',
        '/ecommerce/france-fr',
        '/developpement-web/france-fr',
        '/seo/france-fr',
        '/video/france-fr',
        '/social-media/france-fr',
        '/app-mobile/france-fr',
        '/adwords/france-fr',
        '/contenu/france-fr',
        '/pub/france-fr',
        '/ergonomie/france-fr',
        '/evenementiel/france-fr',
        '/s/identite-graphique/france-fr',
        '/i/web-development/belgium-be', //@TODO: temporarily added for testing
      ],
    },
  },
  'experience-level': {
    defaultValue: false,
    variants: ['control', 'variation-donut', 'variation-bar'],
    experiment: {
      key: 'experience-level',
      domains: ['www.sortlist.com', 'www.sortlist-test.com', 'localhost'],
      pathnames: ['/*'],
    },
  },
  'reduce-display-information': {
    defaultValue: false,
    variants: ['control', 'variation-removal'],
    experiment: {
      key: 'reduce-display-information',
      domains: ['www.sortlist.de', 'www-de.sortlist-test.com', 'localhost'],
      pathnames: ['**'],
    },
  },
  project_highlight: {
    defaultValue: false,
  },
  calcom_settings: {
    defaultValue: false,
    enabledForEnvironments: ['development'],
  },
  calcom_flow: {
    defaultValue: false,
    enabledForEnvironments: ['development'],
  },
} as const satisfies Record<string, FeatureToggleConfig>;
