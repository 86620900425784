var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v2024.42.1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initClient } from '@sortlist-frontend/mlm';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_PUBLIC_SENTRY_DSN;

initClient(SENTRY_DSN || 'https://fac4ef76553f45bca772181d27552c59@o27611.ingest.sentry.io/5191789', {
  // @link https://docs.sentry.io/platforms/javascript/configuration/tree-shaking/
  // @link https://develop.sentry.dev/sdk/performance/
  // To turn it off and avoid bundling the tracing package (30kb), remove the line
  // @see https://github.com/getsentry/sentry-javascript/issues/4722
  tracesSampleRate: ['true', '1'].includes(process.env.NEXTJS_SENTRY_TRACING ?? '') ? 0.005 : 0,
  beforeSend(event, hint) {
    const error = hint.originalException;

    let errorMsg: string | null = null;
    if (error instanceof Error) {
      errorMsg = error.message;
    } else if (typeof error === 'string') {
      errorMsg = error;
    }

    if (error && errorMsg?.match(/storage access denied/i)) {
      event.fingerprint = ['storage-access-denied'];
    }

    if (
      errorMsg &&
      (errorMsg.match(/Network Error/i) ||
        errorMsg.match(/Fetch Error/i) ||
        errorMsg.match(/Request aborted/i) ||
        errorMsg.match(/XHR Error/i) ||
        errorMsg.match(/adsbygoogle/i) ||
        errorMsg.match(/Failed to fetch/i))
    ) {
      return null;
    }

    // When we're developing locally
    if (process.env.NODE_ENV !== 'production') {
      // Don't actually send the errors to Sentry
      return null;
    }

    return event;
  },
});
